:root {
  //TenX landing page style Colors
  --tenx_primary_green: #b0fd33;

  --tenx_landing_bg_black: #131114;
  --signal_pg_bg: #484a46;
  --roadmap_pg_bg: #ccfc50;

  --tenx_grey_1: #2a2a2f;
  --tenx_grey_2: #434345;

  --trade-card-title: #ccfd51;
  --trade-card-subtitle: #969696;
  --trade-card-subtitle-white: #f6f6f6;
  --trade-card-background: #2f2e32;
  --trade-card-pink: #f342ba;
}

// colors-variables-common
$limegreen: #ccfc50;
$black: #000000;
$greyblack: #202020;
$white: #ffffff;
$darkgrey: #2f2e32;
$placeholder: #aeaeae;
$boxgrey: #434345;
$titleColor: #dedede;
$sidebar: #2c2b2f;
$paragraphs: #d7d7d7;
$tablebrdr: #3e3d41;
$cellodd: #272629;
$cellhead: #8c8c8c;
$celldata: #e7e7e7;
$red: #f40216;
$subtxt: #696969;
$label: #cbcbcb;
$green: #3fd00c;
$deletered: #cc2d3a;
$editorheader: #505050;
$offwhite: #f5f5f5;
$subjectPlaceholder: #ededed;
$freeAccessContent: #C8C8C8;

@mixin transitionAll {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
