/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";
@import "./varibales.scss";

@font-face {
  font-family: Poppins;
  src: url(assets/fonts/Poppins-Regular.ttf) format("opentype");
}

body {
  font-family: Poppins;
}

a {
  text-decoration: none;
  cursor: pointer;
}

img {
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style: none;
}

button {
  outline: none;
  box-shadow: none;
  border: none;
  background: none;
  padding: 0;
  &:focus {
    outline: none;
    box-shadow: none;
    border: none;
  }
}

.form-control {
  color: #dbd9d9 !important;
  &:focus {
    box-shadow: none;
  }
}

.wrapper {
  background: #202020;
}
.content_body {
  width: 100%;
  display: block;
  padding-left: 0px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  min-height: 100vh;
  @media screen and (max-width: 767px) {
    padding-left: 0;
    min-height: auto;
  }
}

.content_body_padding {
  width: 100%;
  display: block;
  padding-left: 84px;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  min-height: 100vh;
  @media screen and (max-width: 767px) {
    padding-left: 0;
    min-height: auto;
  }
}

.admin_view {
  &.content_body_padding {
    padding-left: 100px;
  }
}

.main_content_wrapper {
  padding: 0 1em;
}

.backbtn_block {
  margin: 1.5em 0 1.5em 0;
  .back_btn {
    display: block;
    color: $titleColor;
    font-size: 0.9em;
  }
}

.breadcrumb_wrap {
  margin-bottom: 1.5em;
  padding: 0 1em;
  .breadcrumb_menus {
    ul {
      display: flex;
      justify-content: stretch;
      align-items: center;
      li {
        display: block;
        position: relative;
        margin-right: 0.4em;
        a {
          display: block;
          color: $paragraphs;
          position: relative;
          padding-right: 1em;
          font-size: 0.9em;
          &:hover {
            color: $limegreen;
          }
          @media screen and (max-width: 580px) {
            font-size: 0.6em;
          }
          &::before {
            content: "\f105";
            position: absolute;
            right: 0;
            top: 0;
            -webkit-font-smoothing: antialiased;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            font-family: "FontAwesome";
          }
          &.back_btn {
            &::before {
              display: none;
            }
          }
        }
        span {
          display: block;
          color: $white;
          font-size: 0.9em;
          padding-left: 0.5em;
          @media screen and (max-width: 580px) {
            font-size: 0.6em;
          }
        }
      }
    }
  }
  .link_btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 767px) {
      justify-content: flex-start;
      margin-top: 1.2em;
    }
    li {
      .link_btn {
        display: block;
        background: transparent;
        border: 1px solid transparent;
        color: $limegreen;
        font-size: 0.9em;
      }
    }
  }
}

.content_box {
  position: relative;
  background: $darkgrey;
  width: 100%;
  padding: 1.5em;
  border-radius: 8px;
}

.pgtitle_block {
  position: relative;
  margin-bottom: 1.5em;
  h2 {
    font-size: 1.2em;
    color: $titleColor;
    display: block;
  }
  .subtxt {
    display: block;
    clear: both;
    font-size: 0.9em;
    color: $subtxt;
    margin-top: 0.5em;
  }
}

.create_pg_width {
  width: 85%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}
.create_pg_width-help {
  width: 70%;
  .delete_btn {
    background: $deletered;
    color: $white;
    padding: 7px 40px;
    border-radius: 4px;
    font-size: 0.875em;
    line-height: 21px;
    float: right;
    font-family: "Poppins";
  }
  @media screen and (max-width: 767px) {
    width: 100%;

    .delete_btn {
      float: left;
      margin-top: 15px;
    }
  }
}
.form-group {
  .angular-editor-toolbar .angular-editor-toolbar-set .angular-editor-button {
    background-color: $editorheader !important;
    border: 1px solid $editorheader !important;
    color: #a1a19c;
  }

  .angular-editor-toolbar {
    background-color: $editorheader !important;
    border: 1px solid $editorheader !important;
    margin: 0.6rem;
    border-radius: 4px;
  }

  .ae-font .ae-picker-label {
    background-color: $editorheader !important;
    border: 1px solid $editorheader !important;
    color: #a1a19c;
  }
  .angular-editor-toolbar .angular-editor-toolbar-set .background {
    background: transparent !important;
    color: #a1a19c !important;
  }

  .ae-font .ae-picker-label:before {
    background: linear-gradient(
      to right,
      rgb(83, 83, 83),
      #666668 100%
    ) !important;
  }
  .angular-editor .angular-editor-wrapper .angular-editor-textarea {
    color: #dbd9d9 !important;
  }

  .angular-editor {
    position: relative;
    background: $boxgrey;
    border: 1px solid #656565;
    border-radius: 6px;
  }

  .angular-editor .angular-editor-wrapper .angular-editor-textarea {
    border: 0px solid #ddd !important;
  }
  .angular-editor-textarea:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
  }
}
textarea {
  resize: none;
}

.rht_cntrl_sec {
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 767px) {
    justify-content: flex-start;
    margin-top: 1.2em;
    width: 100%;
  }
  .search_filter {
    position: relative;
    display: flex;
    @media screen and (max-width: 640px) {
      flex-direction: column;
      width: 100%;
    }
    .form-group {
      position: relative;
      min-width: 15em;
      margin-right: 1em;
      @media screen and (max-width: 640px) {
        min-width: 100%;
      }
      .srch_icon {
        display: block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0.7em;
        color: $placeholder;
        font-size: 0.8em;
      }
      .form-control {
        background: #3f3e43;
        border-radius: 6px;
        padding-left: 2.4em;
        border: 1px solid transparent;
        font-size: 0.9em;
        &:focus {
          box-shadow: none;
          border: 1px solid transparent;
        }
        &::placeholder {
          color: $placeholder;
          font-size: 0.9em;
        }
      }
    }
  }
  .filter_btn {
    background: #3f3e43;
    border-radius: 6px;
    min-width: 8em;
    color: $placeholder;
    font-size: 0.9em;
    @include transitionAll;
    @media screen and (max-width: 640px) {
      min-width: 100%;
      margin-top: 1em;
      padding: 0.8em;
    }
    &:hover {
      background: $limegreen;
      color: $black;
    }
    img {
      display: inline-block;
      width: 1em;
    }
  }
}

.table_wrapper {
  @media screen and (max-width: 992px) {
    .table-responsive-md {
      overflow: hidden;
      overflow-x: auto;
    }
  }

  .table {
    border: 1px solid $tablebrdr;
    border-radius: 8px;
    @media screen and (max-width: 992px) {
      min-width: 1000px;
    }
    thead {
      background: #2f2e32 !important;
    }
    th {
      padding: 1.3em;
      color: $cellhead;
      font-size: 0.9em;
      font-weight: normal;
    }
    .text-limit {
      max-width: 200px;
      max-height: 80px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
    td {
      padding: 1.3em;
      font-size: 0.9em;
      color: $celldata;
      font-weight: normal;
      vertical-align: middle;
      .actions_tbl {
        display: flex;
        justify-content: center;
        align-items: center;
        .tbl_actn_btn {
          color: $cellhead;
          margin-right: 1.5em;
          &.del_btn {
            color: $red;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      p {
        margin-bottom: 0;
      }
    }
    tbody {
      tr {
        &:nth-child(odd) {
          background: $cellodd;
        }
      }
    }
  }
}

.form_section {
  .form-group {
    position: relative;
    margin-bottom: 2em;
    label {
      color: $white;
      display: block;
      clear: both;
      margin-bottom: 0.5em;
      font-size: 0.9em;
    }
    .form-control {
      background: #434345;
      border: 1px solid #656565;
      border-radius: 6px;
      height: 48px;
      padding: 0 1em;
      width: 100%;
      font-size: 0.9em;
      &::placeholder {
        color: #8d8d8d;
      }
    }
  }
  .upload_section {
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
    cursor: pointer;
    .upload_lbl {
      display: block;
      background: $boxgrey;
      border-radius: 5px;
      color: $white;
      text-align: center;
      padding: 2.5em;
      margin: 0.6em;
      cursor: pointer;
      img {
        display: inline-block;
        width: 1em;
        position: relative;
        top: -1px;
      }
    }
  }
  .btn_grp {
    margin-top: 1.5em;
    display: flex;
    justify-content: flex-end;
    .btn {
      height: 42px;
      line-height: 42px;
      min-width: 140px;
      padding: 0;
      text-align: center;
      margin-right: 1em;
      border-radius: 4px;
      font-weight: 500;
      font-size: 1em;
      &:last-child {
        margin-right: 0;
      }
      &.brdr_btn {
        border: 1px solid $limegreen;
        color: $limegreen;
      }
      &.fill_btn {
        border: 1px solid $limegreen;
        background: $limegreen;
        color: $black;
      }
    }
  }
}

.pagination_wrapper {
  .ngx-pagination {
    margin-left: 0;
    margin-bottom: 1rem;
    background: #3f3e43 !important;
    border-radius: 4px;
    color: #9e9e9e;
  }
  .ngx-pagination li {
    border-right: 1px solid #646365;
    padding: 8px;
  }
  .ngx-pagination li:last-child {
    border-right: 0px solid #646365;
    padding: 8px;
  }
  .ngx-pagination a,
  .ngx-pagination button {
    color: #cacaca;
  }
  .ngx-pagination .disabled {
    color: #cacaca;
    cursor: default;
    padding: 11px !important;
  }
  .ngx-pagination a:hover,
  .ngx-pagination button:hover {
    background: transparent;
  }
  .ngx-pagination .current {
    background: transparent;
    color: #ffffff;
    cursor: default;
    border-right: 1px solid #646365;
    padding: 11px 16px;
  }
  .pagination-result {
    font-style: normal;
    font-weight: 500;
    font-size: 0.9em;
    line-height: 24px;
    color: #d3d3d3;
  }
}
.gap-50 {
  gap: 50px;
}

.arrow-position {
  position: relative;
  .arrow {
    position: absolute;
    top: 39px;
    right: 8px;
  }
}

.site_color_btn {
  background: $limegreen;
  border: 1px solid $limegreen;
  border-radius: 38px;
  color: $black;
  display: block;
  text-align: center;
  min-width: 161px;
  height: 42px;
  line-height: 42px;
  @include transitionAll;
  &:hover {
    background: $white;
    border-color: $white;
  }
}

.pagin_block {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
  }
  .pagin_cntrls {
    margin-left: 1.5em;
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
    ul {
      li {
        span {
          font-size: 0.9em;
          color: #9e9e9e;
          &:hover {
            color: $limegreen;
          }
        }
        &.current {
          span {
            color: $white;
            &:hover {
              color: $white;
            }
          }
        }
      }
    }
  }
}

.txt_limit {
  width: 600px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.sidebar_active {
  &::-webkit-scrollbar {
    display: none;
  }
  .menu_sidebar {
    transform: translateX(0);
  }
}

.tog_circle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 2em;
  height: 2em;
  @media screen and (max-width: 767px) {
    transform: scale(0.7);
  }
  span {
    display: block;
    margin-bottom: 0.1em;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: $limegreen;
    // @include transitionAll;
    transition: all 0.2s ease-in-out;
  }
}

.sidebar_active {
  @media screen and (max-width: 767px) {
    position: relative;
    height: 100%;
  }
  .tog_circle {
    position: relative;
    span {
      &:first-child {
        width: 12px;
        height: 5px;
        border-radius: 6px;
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        opacity: 0;
      }
      &:nth-child(4) {
        width: 100%;
        height: 5px;
        border-radius: 6px;
      }
      &:nth-child(5) {
        width: 0;
        height: 0;
      }
      &:nth-child(6) {
        width: 0;
        height: 0;
      }
      &:nth-child(7) {
        opacity: 0;
      }
      &:nth-child(8) {
        opacity: 0;
      }
      &:nth-child(9) {
        width: 12px;
        height: 5px;
        border-radius: 6px;
      }
    }
  }
}

.modal {
  box-shadow: none !important;
  border: none !important;
  --bs-modal-border-color: transparent !important;
  --bs-modal-border-width: 0 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 0.5em;
}

.ng-select .ng-clear-wrapper {
  display: none !important;
}

// datepicker admin side user popup

.date_picker_input {
  .dropdown-menu.show {
    background: #353437 !important;
    padding: 1em;
  }
  .ngb-dp-header {
    background: #353437 !important;
  }
  .ngb-dp-month-name {
    background: #353437 !important;
    color: #d6dae0;
    font-size: 0.85em;
  }
  .ngb-dp-weekdays {
    background: #353437 !important;
    border-bottom: none;
  }
  .ngb-dp-navigation-chevron {
    border-color: #ffffff !important;
  }
  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    font-style: normal;
  }
  .ngb-dp-weekday {
    color: #929292;
  }
  .custom-day {
    color: #d6dae0;
    font-size: 0.8em;
  }
  ngb-datepicker-navigation-select > .form-select {
    margin-right: 1em;
    background: #434345;
    border: none;
    color: #ccc;
    background-image: url("../src/assets/img/arrowdown.png");
    background-repeat: no-repeat;
    background-position: 90% center;
    &:focus {
      box-shadow: none;
    }
  }

  .custom-day.focused {
    background-color: #7a7a7a;
  }

  .custom-day.range,
  .custom-day:hover {
    background-color: #7a7a7a;
    color: white;
  }
  .custom-day.faded {
    background-color: #525058;
  }
  .ngb-dp-months {
    padding-top: 1em;
  }
}

.logo_normal {
  display: block !important;
}

.logo_black {
  display: none !important;
}

.logo_black_green {
  display: none !important;
}

.future_market_body {
  .header_wrapper {
    background: $limegreen !important;
    .navbar_inner {
      .header_nav_right {
        .notify {
          color: $black !important;
        }
        .user_profile {
          background: rgba(0, 0, 0, 0.07) !important;
          .user_name {
            color: $black !important;
            font-weight: 500 !important;
          }
        }
      }
    }
  }
  .wrapper {
    background: $limegreen !important;
  }
  .signals_menus_block {
    border-bottom: 1px solid #8bb128 !important;
    ul {
      li {
        a {
          color: $black !important;
          font-weight: 500 !important;
        }
        &.signal_active {
          a {
            color: $black !important;
            &::before {
              background: $black !important;
            }
          }
        }
      }
    }
  }
  .rht_cntrl_sec {
    .search_filter {
      .form-group {
        .srch_icon {
          color: $black !important;
        }
        .form-control {
          background: rgba(0, 0, 0, 0.07) !important;
          color: $black !important;
          &::placeholder {
            color: $black !important;
          }
        }
      }
    }
  }
  .signals_content_block {
    .content_box {
      background: rgba(0, 0, 0, 0.09) !important;
      .signal_subtabs {
        border-bottom: 1px solid #8bb128 !important;
        ul {
          background: $black !important;
        }
        .rht_cntrl_sec {
          .filter_btn {
            background: $black !important;
            color: $white !important;
            &:hover {
              background: #283c05 !important;
              color: $white !important;
            }
          }
        }
      }
    }
    .futuremarket_signal_lists {
      .each_signal_card {
        background: $black !important;
        .card_inner {
          .signal_card_left {
            border-right: 1px solid rgba(244, 244, 244, 0.15) !important;
            .card_left_top {
              border-bottom: 1px solid rgba(244, 244, 244, 0.15) !important;
              .card_data {
                label {
                  color: #f8f8f8 !important;
                }
                span {
                  color: #ffffff !important;
                }
              }
            }
            .card_status {
              .each_status {
                label {
                  color: #f8f8f8 !important;
                }
                span {
                  color: #ffffff !important;
                }
              }
            }
            .card_left_bottom {
              .card_left_bottom_inner {
                .value_block {
                  .value_row {
                    .value_col {
                      .each_value {
                        span {
                          color: #ffffff !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .signal_card_right {
            .card_total_calc {
              h3 {
                color: $white !important;
              }
              .signal_lines {
                li {
                  span {
                    background: $limegreen !important;
                  }
                  label {
                    color: #f8f8f8 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .calc_mobile_block {
      .card_total_calc {
        h3 {
          color: $white !important;
        }
        .signal_lines {
          li {
            span {
              background: $limegreen !important;
            }
            label {
              color: #f8f8f8 !important;
            }
          }
        }
      }
    }
  }
  .footer_wrapper {
    .footer_nav_block {
      .footer_menus_wrp {
        .footer_menus {
          ul {
            li {
              a {
                color: $black !important;
                &:hover {
                  color: $black !important;
                }
              }
            }
          }
        }
      }
    }
    .social_media {
      li {
        a {
          color: $black !important;
          &:hover {
            color: $black !important;
          }
        }
      }
    }
  }
  .logo_normal {
    display: none !important;
  }

  .logo_black {
    display: block !important;
  }
}

.normal_logo {
  display: block !important;
}

.forex_header_logo_img {
  display: none !important;
}

.forex_market_body {
  .header_wrapper {
    background: $white !important;
    .navbar_inner {
      .header_nav_right {
        .notify {
          color: $black !important;
        }
        .user_profile {
          background: rgba(255, 255, 255, 0.07) !important;
          border: 1px solid #f0eded;
          .user_name {
            color: $black !important;
            font-weight: 500 !important;
          }
        }
      }
    }
  }
  .wrapper {
    background: $white !important;
  }
  .signals_menus_block {
    border-bottom: 1px solid #cfcfcf !important;
    ul {
      li {
        a {
          color: $black !important;
          font-weight: 500 !important;
        }
        &.signal_active {
          a {
            color: $black !important;
            &::before {
              background: $black !important;
            }
          }
        }
      }
    }
  }
  .rht_cntrl_sec {
    .search_filter {
      .form-group {
        .srch_icon {
          color: #717172 !important;
        }
        .form-control {
          background: rgba(255, 255, 255, 0.07) !important;
          color: $black !important;
          border: 1px solid #f0eded;
          &::placeholder {
            color: #717172 !important;
          }
        }
      }
    }
  }
  .signals_content_block {
    .content_box {
      background: #f8f8f8;
      .signal_subtabs {
        border-bottom: 1px solid #cfcfcf !important;
        ul {
          background: #ffffff !important;
        }
        .rht_cntrl_sec {
          .filter_btn {
            background: $black !important;
            color: $white !important;
            &:hover {
              background: #283c05 !important;
              color: $white !important;
            }
          }
        }
      }
    }
    .futuremarket_signal_lists {
      .each_signal_card {
        background: $black !important;
        .card_inner {
          .signal_card_left {
            border-right: 1px solid rgba(244, 244, 244, 0.15) !important;
            .card_left_top {
              border-bottom: 1px solid rgba(244, 244, 244, 0.15) !important;
              .card_data {
                label {
                  color: #f8f8f8 !important;
                }
                span {
                  color: #ffffff !important;
                }
              }
            }
            .card_status {
              .each_status {
                label {
                  color: #f8f8f8 !important;
                }
                span {
                  color: #ffffff !important;
                }
              }
            }
            .card_left_bottom {
              .card_left_bottom_inner {
                .value_block {
                  .value_row {
                    .value_col {
                      .each_value {
                        span {
                          color: #ffffff !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .signal_card_right {
            .card_total_calc {
              h3 {
                color: $white !important;
              }
              .signal_lines {
                li {
                  span {
                    background: $limegreen !important;
                  }
                  label {
                    color: #f8f8f8 !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .forex_signal_lists {
      .each_signal_card {
        background: $white;
        border: 1px solid #f0eded;
        .card_inner {
          .signal_card_left {
            border-right: 1px solid rgba(57, 56, 56, 0.2);
            @media screen and (max-width: 767px) {
              border-right: none;
            }
            .card_left_top {
              border-bottom: 1px solid rgba(57, 56, 56, 0.2);
              .card_data {
                label {
                  color: #00970f;
                  font-weight: 600;
                }
                span {
                  color: $black;
                  font-weight: 600;
                }
              }
            }
            .card_status {
              .each_status {
                label {
                  color: #7d7d7d;
                  font-weight: 600;
                }
                span {
                  color: $black;
                  font-weight: 500;
                }
              }
            }
            .card_left_bottom {
              .card_left_bottom_inner {
                .value_block {
                  .value_row {
                    .value_col {
                      .each_value {
                        label {
                          background: #f3f3f3;
                          color: $black;
                        }
                        span {
                          color: $black;
                          font-weight: 600;
                          em {
                            color: #00970f;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .card_left_middle {
              background: #f3f3f3;
              .calc_sec {
                .each_calc {
                  label {
                    font-weight: 600;
                  }
                  &.entry {
                    label {
                      color: #00970f;
                    }
                    span {
                      color: #00970f;
                    }
                  }
                }
              }
            }
          }
          .signal_card_right {
            .card_total_calc {
              h3 {
                color: $black;
                font-weight: 600;
              }
              span {
                background: #f3f3f3;
                color: $black;
              }
              .signal_lines {
                li {
                  span {
                    background: #009860;
                  }
                  label {
                    color: $black;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .calc_mobile_block {
      .card_total_calc {
        h3 {
          color: $black !important;
        }
        .signal_lines {
          li {
            span {
              background: #009860 !important;
              &.inactive {
                background: #cccccc !important;
              }
            }
            label {
              color: $black !important;
            }
          }
        }
      }
    }
  }
  .footer_wrapper {
    .footer_nav_block {
      .footer_menus_wrp {
        .footer_menus {
          ul {
            li {
              a {
                color: $black !important;
                &:hover {
                  color: $black !important;
                }
              }
            }
          }
        }
      }
    }
    .social_media {
      li {
        a {
          color: $black !important;
          &:hover {
            color: $black !important;
          }
        }
      }
    }
  }

  .logo_normal {
    display: none !important;
  }

  .logo_black {
    display: none !important;
  }

  .logo_black_green {
    display: block !important;
  }

  .normal_logo {
    display: none !important;
  }

  .forex_header_logo_img {
    display: block !important;
  }
}

.forex_chart_body {
  .header_wrapper {
    background: $white !important;
    .navbar_inner {
      .header_nav_right {
        .notify {
          color: $black !important;
        }
        .user_profile {
          background: rgba(255, 255, 255, 0.07) !important;
          border: 1px solid #f0eded;
          .user_name {
            color: $black !important;
            font-weight: 500 !important;
          }
        }
      }
    }
  }
  .wrapper {
    background: $white !important;
  }
  .signals_menus_block {
    border-bottom: 1px solid #cfcfcf !important;
    ul {
      li {
        a {
          color: $black !important;
          font-weight: 500 !important;
        }
        &.signal_active {
          a {
            color: $black !important;
            &::before {
              background: $black !important;
            }
          }
        }
      }
    }
  }
  .rht_cntrl_sec {
    .search_filter {
      .form-group {
        .srch_icon {
          color: #717172 !important;
        }
        .form-control {
          background: rgba(255, 255, 255, 0.07) !important;
          color: $black !important;
          border: 1px solid #f0eded;
          &::placeholder {
            color: #717172 !important;
          }
        }
      }
    }
  }

  .footer_wrapper {
    .footer_nav_block {
      .footer_menus_wrp {
        .footer_menus {
          ul {
            li {
              a {
                color: $black !important;
                &:hover {
                  color: $black !important;
                }
              }
            }
          }
        }
      }
    }
    .social_media {
      li {
        a {
          color: $black !important;
          &:hover {
            color: $black !important;
          }
        }
      }
    }
  }

  .logo_normal {
    display: none !important;
  }

  .logo_black {
    display: none !important;
  }

  .logo_black_green {
    display: block !important;
  }

  .normal_logo {
    display: none !important;
  }

  .forex_header_logo_img {
    display: block !important;
  }
  .chart_listing_block {
    background: #f3f3f3;
  }
  .pagination_wrapper {
    .pagination-result {
      color: $black;
      font-weight: 600;
    }
  }
}

.future_chart_body {
  .header_wrapper {
    background: $limegreen !important;
    .navbar_inner {
      .header_nav_right {
        .notify {
          color: $black !important;
        }
        .user_profile {
          background: rgba(0, 0, 0, 0.07) !important;
          .user_name {
            color: $black !important;
            font-weight: 500 !important;
          }
        }
      }
    }
  }
  .wrapper {
    background: $limegreen !important;
  }
  .signals_menus_block {
    border-bottom: 1px solid #8bb128 !important;
    ul {
      li {
        a {
          color: $black !important;
          font-weight: 500 !important;
        }
        &.signal_active {
          a {
            color: $black !important;
            &::before {
              background: $black !important;
            }
          }
        }
      }
    }
  }
  .rht_cntrl_sec {
    .search_filter {
      .form-group {
        .srch_icon {
          color: $black !important;
        }
        .form-control {
          background: rgba(0, 0, 0, 0.07) !important;
          color: $black !important;
          &::placeholder {
            color: $black !important;
          }
        }
      }
    }
  }

  .footer_wrapper {
    .footer_nav_block {
      .footer_menus_wrp {
        .footer_menus {
          ul {
            li {
              a {
                color: $black !important;
                &:hover {
                  color: $black !important;
                }
              }
            }
          }
        }
      }
    }
    .social_media {
      li {
        a {
          color: $black !important;
          &:hover {
            color: $black !important;
          }
        }
      }
    }
  }
  .logo_normal {
    display: none !important;
  }

  .logo_black {
    display: block !important;
  }
  .pagination_wrapper {
    .pagination-result {
      color: $black;
      font-weight: 600;
    }
  }
}

.trade_btn {
  display: inline-block;
  text-align: center;
  padding: 0.3em 0.5em;
  font-size: 0.75em;
  background: #054fbe;
  border-radius: 4px;
  position: relative;
  margin-left: 1em;
  color: $white;
  @include transitionAll;
}

.trade_tooltip .tooltip-inner {
  background-color: #054fbe;
  font-size: 0.8em;
}
.trade_tooltip.bs-tooltip-end .tooltip-arrow::before {
  border-right-color: #054fbe;
}
.trade_tooltip.bs-tooltip-start .tooltip-arrow::before {
  border-left-color: #054fbe;
}
.trade_tooltip.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #054fbe;
}
.trade_tooltip.bs-tooltip-bottom .tooltip-arrow::before {
  border-bottom-color: #054fbe;
}

@media screen and (max-width: 767px) {
  .card_data {
    &.bk_mrk {
      position: absolute !important;
      top: 1em;
      right: 1em;
      display: flex;
    }
    .trade_btn {
      margin-left: 1.5em;
    }
  }
  .each_signal_card {
    .card_inner {
      .signal_card_left {
        .card_left_top {
          .bookmark_btn {
            position: relative !important;
            top: auto !important;
            right: auto !important;
          }
        }
      }
    }
  }
}

.signal_lines {
  li {
    &:empty {
      display: none;
    }
    span {
      &.inactive {
        background: #3f3e43 !important;
      }
    }
  }
}
.forex_market_preview {
  .signal_card_preview {
    .each_signal_card {
      .card_inner {
        .signal_card_right {
          .card_total_calc {
            .signal_lines {
              li {
                span {
                  &.inactive {
                    background: #cccccc !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.forex_signal_lists {
  .bookmark_btn {
    color: $black !important;
  }
  .each_signal_card {
    .card_inner {
      .signal_card_right {
        .card_total_calc {
          .signal_lines {
            li {
              span {
                background: #00970f !important;
                &.forex {
                  background: #cccccc !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .signal_subtabs {
    > .row {
      > .col-12 {
        flex: 0 0 auto;
        width: 100%;
      }
    }
    ul {
      width: 100%;
      margin-bottom: 1em;
      overflow: hidden;
      overflow-x: auto;
      align-items: center;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
      }
    }
    .rht_cntrl_sec {
      @media screen and (max-width: 767px) {
        margin-top: 0;
      }
    }
  }
}
